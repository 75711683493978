'use strict';

var StringMask = require('string-mask');
var maskFactory = require('mask-factory');

/**
 * FIXME: all numbers will have 9 digits after 2016.
 * see http://portal.embratel.com.br/embratel/9-digito/
 */
var phoneMask8D = new StringMask('(00) 0000-0000'),
	phoneMask9D = new StringMask('(00) 00000-0000'),
	phoneMask0800 = new StringMask('0000-000-0000');

module.exports = maskFactory({
	clearValue: function(rawValue) {
		return rawValue.toString().replace(/[^0-9]/g, '').slice(0, 11);
	},
	format: function(cleanValue) {
		var formatedValue;
		if (cleanValue.indexOf('0800') === 0) {
			formatedValue = phoneMask0800.apply(cleanValue);
		} else if (cleanValue.length < 11) {
			formatedValue = phoneMask8D.apply(cleanValue) || '';
		} else {
			formatedValue = phoneMask9D.apply(cleanValue);
		}

		return formatedValue.trim().replace(/[^0-9]$/, '');
	},
	getModelValue: function(formattedValue, originalModelType) {
		var cleanValue = this.clearValue(formattedValue);

		return originalModelType === 'number' ? parseInt(cleanValue) : cleanValue;
	},
	validations: {
		brPhoneNumber: function(value) {
			var valueLength = value && value.toString().length;
			return valueLength === 10 || valueLength === 11;
		}
	}
});
